import styled from 'styled-components'
import './App.css';

const Background = styled.div`
background-color: #282c34;
display:flex;
flex-flow:column;
height: 100vh;
width:100vw;
justify-content: center;
align-items:center;
> * + * {
  margin-top: 2rem;
}
`

const Title = styled.h1`
color:white;
display:flex;
align-items:center;
justify-content:center;
font-size: 10rem;
font-weight: 100;
letter-spacing: 0.5rem;
margin:0;
`

const Subheading = styled.div`
color:white;
font-size: 3rem;
font-weight: 100;
`

function App() {
  return (
    <Background >
      <Title>&LAB</Title>
      <Subheading >
        Coming soon
      </Subheading>
    </Background>
  );
}

export default App;
